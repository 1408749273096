@charset 'utf-8';

$global-width: rem-calc(1120);
$lightgrey: #F8F8FA;
$blueNew: #002864;
$blueNewFocus: #003078;
$blueNewLight: #0078D6;
$blueNewLightFocus: #006BBF;
$blue: $blueNew;
$blue2: $blueNewLight;

@import 'settings';
@import 'common';
@import 'foundation';
@import 'motion-ui';
@import 'landing';

@include foundation-global-styles;
// // @include foundation-grid;
// // @include foundation-flex-grid;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// // @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-card;
// @include foundation-close-button;
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
@include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-prototype-classes;
@include motion-ui-transitions;
@include motion-ui-animations;

/*
font-family: 'Open Sans', sans-serif;
font-family: 'Lexend', sans-serif;
font-family: 'Covered By Your Grace', cursive;
font-family: 'Lexend', sans-serif;
font-family: 'Lexend', sans-serif;
*/

*:focus {
    outline: none !important;
}

/*
.grid-container{
    max-width:1120px;
}
*/


body {
    color: #323232;
    font-size: 16px;
    line-height: 21px;
    font-family: Lexend, sans-serif;

    p {
        line-height: 21px;
        letter-spacing: 0.16px;
    }

    a {
        transition: color .4s;
    }

    iframe {
        max-width: 100%;
    }

    &.page-template-page-carriere {
        #intro {
            em {
                @extend h3;
            }
        }

        .carriere {
            margin: 80px 0;

            @media screen and (max-width: 640px) {
                gap: 24px;
                margin: 40px 0;
            }

            .pic {
                width: 100%;
                min-height: 350px;
                background-size: cover;
                background-position: center center;
            }

            .button {
                margin-bottom: 0;
            }
        }
    }

    &.home {
        .wrapper #body-main section#banner {

            &:before {
                display: none;
            }

            @media screen and (max-width: 1023px) {
                padding: 40px 0 80px !important;

                h1 {
                    margin-top: 0 !important;
                }
            }
        }
    }

    .reveal-overlay {
        .reveal {
            padding-top: 2rem;

            .close-button {
                position: absolute;
                right: .6rem;
                top: .2rem;
                cursor: pointer;

                span {
                    font-size: 2rem;
                }
            }
        }
    }

    div.button, a.button, button.button {
        background-color: #000;
        padding: 16px;
        margin-top: 1rem;
        transition: all .4s;
        border-radius: 4px;

        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        letter-spacing: 0.16px;

        i, span {
            transition: color .4s;
        }

        &:hover, &:active, &:focus {
            background-color: transparent;

            i, span {

            }
        }

        &.-w100 {
            width: 100%;
            margin: 0;
        }

        &.-icon {
            display: flex;
            align-items: center;
            gap: 8px;
            justify-content: center;

            svg {
                display: block;
            }
        }

        &.button-blue {
            border-color: $blueNew;
            background-color: $blueNew;

            &:hover {
                box-shadow: 0px 3px 6px 0px rgba(0, 40, 100, 0.12), 0px -3px 6px 0px rgba(0, 40, 100, 0.12);
            }

            &:focus-visible {
                border-color: $blueNewFocus;
                background-color: $blueNewFocus;
            }

            &.-secondary {
                color: $blueNew;
                border-color: $blueNew;
                background-color: transparent;

                &:hover, &:focus-visible {
                    box-shadow: inset 0 0 0 1px $blueNew;
                }
            }
        }

        &.button-blue-2 {
            border-color: $blueNewLight;
            color: $blueNewLight;
            background-color: #fff;

            &:hover, &:focus-visible {
                box-shadow: inset 0 0 0 1px $blueNewLight;
            }
        }

        &.button-blue-light {
            border-color: $blueNewLight;
            background-color: $blueNewLight;

            &:hover {
                box-shadow: 0px 3px 6px 0px rgba(0, 40, 100, 0.12), 0px -3px 6px 0px rgba(0, 40, 100, 0.12);
            }

            &:focus-visible {
                border-color: $blueNewLightFocus;
                background-color: $blueNewLightFocus;
            }
        }

        &.button-white {
            color: $blue2;
            border-color: #fff;
            background-color: #fff;

            &:hover {
                color: #fff;
                background-color: transparent;
            }

            &.-secondary {
                color: white;
                border-color: white;
                background-color: transparent;

                &:hover, &:focus-visible {
                    box-shadow: inset 0 0 0 1px white;
                }
            }
        }

        &.button-pink {
            @extend .button-blue;
        }
    }

    .link {
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: 0.14px;
        color: $blueNewLight;
        text-decoration: underline;
        text-underline-offset: 4px;
        margin-top: auto;

        &:hover {
            color: $blueNewFocus;
        }
    }

    ul {
        li {
            list-style: none;
        }
    }

    h1 {
        font-size: 64px;
        font-weight: 700;
        line-height: 110%;

        @media screen and (max-width: 1023px) {
            font-size: 48px;
        }
    }

    h2 {
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
        color: $blueNew;
        margin-bottom: 16px;

        @media screen and (max-width: 1023px) {
            font-size: 40px;
        }

        em {
            text-transform: none;
            color: $blueNewLight;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 125%;
            padding-bottom: 0;
            margin: 8px 0 0;
            display: block;
        }
    }

    h3 {
        display: block;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
        color: $blueNew;
        padding-bottom: 0;
        margin-bottom: 16px;

        @media screen and (max-width: 1023px) {
            font-size: 28px;
        }

        em {
            text-transform: none;
            color: $blueNewLight;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 125%;
            padding-bottom: 0;
            margin: 4px 0 0;
            display: block;

            @media screen and (max-width: 1023px) {
                font-size: 16px;
            }
        }
    }

    #no-compatible {
        img {
            margin-top: 1rem;
            margin-bottom: 2rem;
        }
    }


    .select2-dropdown {
        border: none;
        box-shadow: 2px 10px 28px -10px rgba(0, 0, 0, 0.75);
    }

    .select2-container {
        width: 100% !important;
    }

    .select2-container--default {

        .select2-selection--single {
            max-width: 100%;
            min-width: 100%;
            height: auto;
            color: #5B5B5B;
            padding: 6px 10px;
            border-radius: 4px;
            border: 1px solid #848484;
            box-shadow: none;
            min-height: 52px;
            margin-top: 24px;
            background: #ffffff url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 16C12.2652 16 12.5196 15.8946 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12 13.5858L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.4804 15.8946 11.7348 16 12 16Z" fill="black"/> </svg>') no-repeat 98% center;

            .select2-selection__arrow {
                display: none;
            }

            .select2-selection__rendered {
                color: #5B5B5B;
                font-size: 16px;
                font-weight: 300;
                line-height: 38px;
                letter-spacing: 0.16px;
            }

            .select2-selection__clear {
                color: black;
                font-size: 2rem;
                font-weight: normal;
                line-height: 32px;
            }

            .select2-container--default .select2-selection--single .select2-selection__placeholder {
                color: #848484;
                font-size: 16px;
                font-weight: 300;
                line-height: 135%;
                letter-spacing: 0.16px;
            }
        }

        .select2-search--dropdown {
            padding: 16px;

            .select2-search__field {
                color: #5B5B5B;
                background: #FFF;
                padding: 6px 10px;
                border-radius: 4px;
                border: 1px solid #848484;
                box-shadow: none;
                margin-bottom: 0;
            }
        }

        .select2-results {
            ul.select2-results__options {
                li.select2-results__option {
                    color: black;
                    padding: 10px 16px;

                    &.select2-results__option--highlighted {
                        background-color: lightgrey;
                    }

                }
            }
        }
    }

    .autocomplete {
        position: relative;

        .autoComplete_wrapper {
            display: block;
        }

        input#autoComplete {
            color: #5B5B5B;
            background: #FFF;
            padding: 6px 56px 6px 24px;
            border-radius: 4px;
            border: 1px solid #848484;
            box-shadow: none;
            min-height: 52px;
            height: auto;
            width: 100%;
            margin-bottom: 0;

            &::placeholder {
                color: #848484;
            }

            &::selection {
                color: $blueNew;
                background-color: #0078d629;
            }
        }

        #autoComplete_list {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 16px;

            .autoComplete_result {
                font-family: Lexend, sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 135%;
                letter-spacing: 0.16px;
                color: $blueNew;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin: auto !important;

                &:hover {
                    background-color: transparent;
                    color: $blueNewLight;

                    &:before {
                        background-color: $blueNewLight;
                    }
                }

                &:before {
                    content: " ";
                    height: 16px;
                    width: 16px;
                    min-width: 16px;
                    margin: auto 10px auto 0;
                    display: block;
                    -webkit-mask: url("../images/icons/chevron-right.svg") no-repeat 50% 50%;
                    -webkit-mask-size: cover;
                    background-color: $blueNew;
                }

                &.autoComplete_selected {
                    background-color: #0078d629 !important;
                }
            }
        }

        label {
            position: absolute;
            top: 14px;
            right: 25px;
        }

        &-header {
            margin-left: 32px;
            display: flex;
            height: auto;
            justify-content: flex-start;

            &-small {
                border-radius: 4px;
                border: 1px solid #D9D9D9;
                margin: 0;

                &:before {
                    margin: 16px !important;
                }

                input#autoCompleteMobile {
                    margin: 0;
                    height: auto;
                    border: 0;
                    box-shadow: none;
                    overflow: hidden;
                    color: #323232;
                    text-overflow: ellipsis;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 18px;
                    letter-spacing: 0.14px;
                    background: transparent;

                    &::placeholder {
                        color: #848484;
                        text-overflow: ellipsis;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 18px;
                        letter-spacing: 0.14px;
                    }
                }
            }

            &:before {
                min-width: 24px;
                width: 24px;
                height: 24px;
                content: " ";
                background-image: url("../images/icons/search.svg");
                margin: auto 16px auto 0;
                display: block;
            }

            input#autoComplete {
                margin: auto !important;
                overflow: hidden;
                color: #323232;
                text-overflow: ellipsis;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 18px;
                letter-spacing: 0.14px;
                border: none;
                box-shadow: none;
                background-image: none !important;
                padding: 0;
                width: 100%;
                border-radius: 0;

                &::placeholder {
                    color: #848484;
                    text-overflow: ellipsis;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 18px;
                    letter-spacing: 0.14px;
                }
            }

            ul#autoComplete_list {
                border-radius: 0;
                width: 100%;

                li.autoComplete_result {
                    margin: 0;
                    width: 100%;
                    border-radius: 0;
                }
            }

            .autoComplete_wrapper {
                display: block;
                width: 100%;
            }
        }

        .no_result {
            margin: 0.15rem auto;
            padding: 0.6rem;
            max-width: 280px;
            border: 0.05rem solid #e3e3e3;
            list-style: none;
            text-align: left;
            font-size: 1.1rem;
            color: rgb(123, 123, 123);
            transition: all 0.1s ease-in-out;
            background-color: #fff;
            border-radius: 0 0 1rem 1rem;
            outline: none;
        }

        .no_result:hover {
            cursor: default;
            background-color: #fff;
            border: 0.05rem solid #e3e3e3;
        }

        .no_result:focus {
            cursor: default;
            background-color: #fff;
            border: 0.05rem solid #e3e3e3;
        }

        .in {
            padding: 0 2rem 0 3.5rem;
            color: rgba(255, 122, 122, 1);
            height: 3rem;
            width: 16.5rem;
            border: 0.05rem solid rgb(255, 122, 122);
            background: url("./images/magnifier.svg") no-repeat left/15% 1.5rem;
            box-shadow: rgba(255, 122, 122, 0.1) 0px 0px 20px 5px;
            position: relative;
            font-size: 1.2rem;
            outline: none;
            border-radius: 50rem;
            border: 0.05rem solid rgb(255, 122, 122);
            caret-color: rgb(255, 122, 122);
            transition: all 0.4s ease;
            -webkit-transition: all -webkit-transform 0.4s ease;
            text-overflow: ellipsis;
        }

        .out {
            position: relative;
            padding: 0 2rem 0 3.5rem;
            height: 2.1rem;
            width: 6rem;
            font-size: 1.2rem;
            outline: none;
            border-radius: 50rem;
            border: 0.05rem solid rgb(255, 122, 122);
            caret-color: rgb(255, 122, 122);
            color: rgba(255, 255, 255, 0);
            background: url("./images/magnifier.svg") no-repeat center/10% 1.5rem;
            transition: all 0.4s ease;
            -webkit-transition: all -webkit-transform 0.4s ease;
            text-overflow: ellipsis;
        }

        .strict {
            display: inline;
        }

        .loose {
            display: inline;
        }

        .selection {
            margin-top: 25vh;
            font-size: 2rem;
            font-weight: bold;
            color: #ffc6c6;
            transition: var(--transition-1);
        }

        .selection::selection {
            color: #64ceaa;
        }
    }

    &.login {
        h1 a {
            height: 90px !important;
            margin-top: 50px !important;
            width: 75% !important;
            background-size: contain !important;
            background-image: url(../images/logo.svg) !important;
        }

        .wp-core-ui .button-primary {
            background-color: #C9510E;
            border-color: #C9510E;
            transition: all .4s;
        }

        .wp-core-ui .button-primary:hover, .wp-core-ui .button-primary:active, .wp-core-ui .button-primary:focus {
            background-color: #fff;
            color: #C9510E;
            border-color: #C9510E;
        }

    }


    .wrapper {
        position: relative;

        /***********************
                LOAD AREA
        ***********************/

        #loader-area {
            width: 100%;
            height: 100vh;
            position: fixed;
            z-index: 11;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .progress {
                width: 300px;
                position: relative;
                margin: 0;
                height: 5px;
            }

            .bar {
                width: 0%;
                height: 5px;
                border-radius: 3px;
            }

            .logo {
                opacity: .3;
                margin-bottom: 2rem;

                img {
                    max-width: 400px;
                }
            }
        }

        /*********************
                HEADER
        *********************/

        header {
            background-color: #fff;
            position: sticky;
            width: 100%;
            z-index: 100;
            transition: all .4s;
            max-width: 100vw;
            top: 0;

            @media screen and (max-width: 767px) {
                border-bottom: 1px solid #D9D9D9;
            }

            .desk-nav {
                border-bottom: 1px solid #d9d9d9;

                hr {
                    height: 0;
                    border: 0;
                    position: relative;

                    &:before {
                        display: inline-block;
                        content: '';
                        position: fixed;
                        left: 50%;
                        height: 1px;
                        width: 100vw;
                        background-color: #D9D9D9;
                        transform: translateX(-50%);
                    }
                }

                .grid-x {
                    height: 75px;
                }

                .main-logo {
                    background-color: #fff;
                    padding: 0;
                    overflow: hidden;
                    text-align: center;
                    max-height: 200px;
                    transition: all .4s;

                    img {
                        transition: all .4s;
                        margin: 24px 0;
                    }
                }

                .main-menu {

                    background-color: #fff;
                    display: flex;
                    justify-content: flex-end;

                    .logo-small {
                        padding-right: 1rem;
                        display: flex;
                        align-items: flex-end;
                        max-width: 0px;
                        opacity: 0;
                    }

                    .menu-items {

                        #menu-contact-menu {
                            > li {
                                &:first-child a:before {
                                    width: 24px;
                                    height: 24px;
                                    content: "";
                                    margin-right: 5px;
                                    display: block;
                                    -webkit-mask: url("../images/icons/mail-check.svg") no-repeat 50% 50%;
                                    -webkit-mask-size: cover;
                                    background-color: $blueNew;
                                    transition: all .4s;
                                }

                                &:last-child.wpml-ls-current-language {
                                    > a:before {
                                        width: 24px;
                                        height: 24px;
                                        content: "";
                                        margin-right: 5px;
                                        display: block;
                                        -webkit-mask: url("../images/icons/globe.svg") no-repeat 50% 50%;
                                        -webkit-mask-size: cover;
                                        background-color: $blueNew;
                                        transition: all .4s;
                                    }

                                    ul.sub-menu {
                                        z-index: 100;

                                        a {
                                            padding: 8px 5px;

                                            &:before {
                                                content: none;
                                            }
                                        }
                                    }
                                }

                                a {
                                    font-size: 14px;
                                    font-weight: 600;
                                    line-height: 18px;
                                    letter-spacing: 0.14px;
                                    color: $blueNew;
                                    transform: none;
                                    text-align: left;
                                    justify-content: left;
                                    text-transform: none;

                                    &:hover {
                                        color: $blueNewLight;

                                        &:before {
                                            background-color: $blueNewLight !important;
                                        }
                                    }
                                }
                            }
                        }

                        #main-menu {
                            > li {
                                &:last-child {
                                    padding-right: 32px;
                                    border-right: 1px solid #D9D9D9;
                                }
                            }
                        }

                        ul {
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            margin: 0 0 0 -8px;

                            li {
                                flex-basis: content;
                                padding-right: 24px;

                                &:first-child {
                                    border-left: none;
                                }

                                &:last-child {
                                    padding-right: 0;
                                }

                                &.menu-item-has-children {
                                    position: relative;

                                    ul.sub-menu {
                                        width: max-content;
                                        overflow: hidden;
                                        display: flex;
                                        justify-content: flex-start;
                                        flex-direction: column;
                                        position: absolute;
                                        max-height: 0px;
                                        height: auto;
                                        transition: max-height .5s;
                                        transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
                                        top: 100%;

                                        li {
                                            padding: 0 1rem;
                                            background-color: #fff;

                                            a {
                                                height: auto;
                                                padding: 8px 5px;
                                                font-size: 16px;
                                                font-weight: 300;
                                                line-height: 18px;
                                                letter-spacing: 0.01em;
                                                color: $blueNew;
                                                transform: none;
                                                text-align: left;
                                                justify-content: left;
                                                text-transform: none;
                                                border: none;

                                                &:hover {
                                                    color: $blueNewLight;

                                                    &:before {
                                                        background-color: $blueNewLight;
                                                    }
                                                }

                                                &:before {
                                                    height: 16px;
                                                    width: 16px;
                                                    margin-right: 5px;
                                                    display: inline-block;
                                                    content: '';
                                                    -webkit-mask: url("../images/icons/arrow-right.svg") no-repeat 50% 50%;
                                                    -webkit-mask-size: cover;
                                                    background-color: $blueNew;
                                                }
                                            }

                                            &.current-menu-item > a {
                                                color: $blueNewLight;

                                                &:before {
                                                    background-color: $blueNewLight;
                                                }
                                            }

                                            &:first-child > a {
                                                padding-top: 24px;
                                            }

                                            &:last-child > a {
                                                padding-bottom: 24px;
                                            }
                                        }
                                    }

                                    &.open {
                                        color: $blueNew;

                                        ul.sub-menu {
                                            transition: max-height 2s;
                                            max-height: 100vh;
                                        }
                                    }
                                }

                                &.current-menu-item, &.current-menu-ancestor, &.current-menu-parent {
                                    > a {
                                        color: $blueNew;
                                        border-bottom: 2px solid;
                                    }
                                }

                                a {
                                    font-size: 16px;
                                    font-weight: 700;
                                    line-height: 21px;
                                    letter-spacing: 0.01em;
                                    color: $blueNew;

                                    justify-content: center;
                                    text-align: center;
                                    display: flex;
                                    align-items: center;
                                    padding: .5rem;
                                    transition: all .4s;
                                    text-transform: uppercase;

                                    &:hover {
                                        color: $blueNewLight;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .small-nav {
                .small-logo {
                    height: 40px;
                    margin: 20px 0;
                }

                .menu-trigger {
                    display: inline-block;
                    cursor: pointer;

                    &.open {
                        .nav-icon span {
                            width: 100% !important;

                            &:nth-child(1) {
                                top: 5px;
                                -webkit-transform: rotate(135deg);
                                -moz-transform: rotate(135deg);
                                -o-transform: rotate(135deg);
                                transform: rotate(135deg);
                            }

                            &:nth-child(2) {
                                opacity: 0;
                                left: -60px;
                            }

                            &:nth-child(3) {
                                top: 5px;
                                -webkit-transform: rotate(-135deg);
                                -moz-transform: rotate(-135deg);
                                -o-transform: rotate(-135deg);
                                transform: rotate(-135deg);
                            }
                        }
                    }

                    .nav-icon {
                        width: 30px;
                        height: 16px;
                        position: relative;
                        display: inline-block;
                        -webkit-transform: rotate(0deg);
                        -moz-transform: rotate(0deg);
                        -o-transform: rotate(0deg);
                        transform: rotate(0deg);
                        -webkit-transition: .5s ease-in-out;
                        -moz-transition: .5s ease-in-out;
                        -o-transition: .5s ease-in-out;
                        transition: .5s ease-in-out;
                        cursor: pointer;

                        span {
                            display: block;
                            position: absolute;
                            height: 3px;
                            width: 100%;
                            background: $blueNew;
                            border-radius: 2px;
                            opacity: 1;
                            left: 0;
                            -webkit-transform: rotate(0deg);
                            -moz-transform: rotate(0deg);
                            -o-transform: rotate(0deg);
                            transform: rotate(0deg);
                            -webkit-transition: .25s ease-in-out;
                            -moz-transition: .25s ease-in-out;
                            -o-transition: .25s ease-in-out;
                            transition: .25s ease-in-out;

                            &:nth-child(1) {
                                top: 0px;
                                width: 33%;
                            }

                            &:nth-child(2) {
                                top: 8px;
                            }

                            &:nth-child(3) {
                                top: 16px;
                                width: 66%;
                            }
                        }
                    }

                    #nav-text {
                        display: inline-block;
                        padding-right: 1rem;
                        //font-size: 15px;
                    }
                }

                .small-sliding-menu {
                    display: none;

                    .main-menu-small {
                        background-color: #fff;
                        width: 100%;
                        position: relative;
                        padding: 1rem 0;
                        padding-bottom: 5rem;
                        overflow-y: scroll;
                        overflow-x: hidden;
                        height: 100vh;

                        ul {
                            margin-top: 20px;
                            margin-left: 0;

                            > li {
                                overflow: hidden;
                                border-bottom: 1px solid rgba(255, 255, 255, .1);

                                > a {
                                    display: block;
                                    font-size: 16px;
                                    font-weight: 700;
                                    line-height: 18px;
                                    letter-spacing: 0.01em;
                                    color: $blueNew;
                                    padding: 16px 0;
                                    text-transform: uppercase;
                                }

                                .sub-menu {
                                    width: 100%;
                                    max-height: 0px;
                                    margin: 0;
                                    padding: 0 1rem;
                                    transition: all .8s;

                                    li {
                                        a {
                                            font-size: 16px;
                                            font-weight: 300;
                                            line-height: 18px;
                                            letter-spacing: 0.01em;
                                            color: $blueNew;
                                            text-transform: none;
                                            padding: .4rem 0;
                                            display: flex;
                                            align-items: center;

                                            &:before {
                                                height: 16px;
                                                width: 16px;
                                                margin-right: 5px;
                                                display: inline-block;
                                                content: '';
                                                -webkit-mask: url("../images/icons/arrow-right.svg") no-repeat 50% 50%;
                                                -webkit-mask-size: cover;
                                                background-color: $blueNew;
                                            }
                                        }
                                    }

                                }

                                &.opened {
                                    .sub-menu {
                                        max-height: 600px;
                                    }
                                }
                            }
                        }

                        #menu-contact {
                            display: flex;
                            flex-direction: row;
                            border-bottom: 1px solid #D9D9D9;
                            border-top: 1px solid #D9D9D9;

                            > li {
                                flex: 1 1 0;
                                margin: 0;
                                border-right: 1px solid #D9D9D9;
                                display: flex;
                                justify-content: center;
                                align-items: flex-start;

                                &:first-child a:before {
                                    width: 24px;
                                    height: 24px;
                                    content: "";
                                    margin-right: 5px;
                                    display: block;
                                    -webkit-mask: url("../images/icons/mail-check.svg") no-repeat 50% 50%;
                                    -webkit-mask-size: cover;
                                    background-color: $blueNew;
                                }

                                &:last-child.wpml-ls-current-language {
                                    flex-direction: column;

                                    > a {
                                        margin: auto;

                                        &:before {
                                            width: 24px;
                                            height: 24px;
                                            content: "";
                                            margin-right: 5px;
                                            display: block;
                                            -webkit-mask: url("../images/icons/globe.svg") no-repeat 50% 50%;
                                            -webkit-mask-size: cover;
                                            background-color: $blueNew;
                                        }
                                    }
                                }

                                &:last-child {
                                    border: none;
                                }

                                > a {
                                    padding: 0.65rem;
                                    display: flex;
                                    align-items: center;

                                    font-size: 14px;
                                    font-weight: 600;
                                    line-height: 18px;
                                    letter-spacing: .01em;
                                    text-transform: none;
                                    color: $blueNew;
                                }
                            }
                        }
                    }
                }
            }
        }

        #body-main {
            position: relative;
            min-height: calc(100vh - 170px);

            .sur-menu {
                .baseline {
                    font-family: Lexend, sans-serif;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 22px;
                    letter-spacing: 0.01em;
                    padding: 15px;
                    color: white;
                    text-align: center;

                    a {
                        display: block;
                        color: white;
                        border: 1px solid;
                        border-radius: 3px;
                        width: fit-content;
                        height: fit-content;
                        margin: auto;
                        padding: 10px 15px;
                        text-transform: uppercase;
                        margin-top: 10px;
                        font-family: Lexend, sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 18px;
                        letter-spacing: 0.01em;
                    }
                }
            }

            section {
                &#banner {
                    overflow: hidden;
                    position: relative;
                    background-size: cover;
                    background-position: center center;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        object-fit: contain !important;
                        width: 100%;
                        height: 100%;

                        @media screen and (max-width: 767px) {
                            position: relative;
                            height: 300px;
                            left: -15px;
                            width: calc(100% + 30px);
                            max-width: none;
                        }
                    }

                    &:before {
                        display: none;
                        content: '';
                        position: absolute;
                        z-index: 5;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: rgba($blueNew, 0.2);
                    }

                    .grid-x {
                        min-height: 40vh;

                        @media screen and (max-width: 767px) {
                            min-height: 0;
                        }

                        h1 {
                            position: relative;
                            z-index: 10;
                            color: #ffffff;
                            margin-top: 32px;
                            margin-bottom: 24px;
                        }

                        .cell.-corner {
                            width: auto;
                            max-width: 42%;

                            @media screen and (max-width: 1279px) {
                                max-width: 68%;
                            }

                            @media screen and (max-width: 767px) {
                                width: 100%;
                                max-width: none;
                            }

                            h1 {
                                position: relative;
                                z-index: 10;
                                color: #ffffff;
                                margin-top: 120px;
                                margin-bottom: 80px;
                                background-color: $blueNew;
                                padding: 24px 56px;
                                font-size: 32px;
                                font-weight: 600;
                                line-height: 110%;

                                @extend .corner__top-left;
                                @extend .corner__bottom-right;
                                @extend .corner__white;

                                &:before, &:after {
                                    height: 32px;
                                    margin: 0 -32px;
                                }

                                @media screen and (max-width: 767px) {
                                    margin-top: 0;
                                    margin-bottom: 0;
                                    padding: 24px 40px;
                                    margin: 0 -15px;

                                    &:before, &:after {
                                        height: 32px;
                                        margin: 0 -24px;
                                    }
                                }
                            }
                        }
                    }
                }

                &#intro {
                    background-color: $lightgrey;

                    h3:last-child {
                        margin-bottom: 0;
                    }

                    .grid-x {
                        padding: 40px 0;
                    }
                }

                &#content {
                    padding: 40px 0;
                }

                .encart-container {
                    margin-top: 2rem;
                    border: 2px solid $lightgrey;
                    padding: 3rem;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    line-height: 2rem;

                    .encart {
                        min-height: 155px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        span.title-pink {
                            color: $blueNew;
                            font-size: 1.8rem;
                            padding-bottom: 0;
                            margin: 0;
                            display: block;
                        }

                        span {
                            display: block;
                        }

                        a.button {
                            margin-bottom: 0;
                            display: inline-block;
                        }
                    }
                }

                .product-container {
                    a {
                        display: flex;
                        padding: 0 12px 24px;

                        .item {
                            padding: 8px;
                            display: flex;
                            width: 100%;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                            box-sizing: border-box;
                            text-align: center;
                            background: #F8F8FA;
                            transition: all .2s ease-in-out;

                            &-visual {
                                position: relative;
                                height: 0;
                                margin: 0 0 8px;
                                padding-top: 100%;
                                width: 100%;

                                img {
                                    position: absolute;
                                    object-fit: contain;
                                    width: 100%;
                                    height: 100%;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }

                            &-name {
                                color: #323232;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 130%;
                                letter-spacing: 0.14px;
                                margin-bottom: auto;
                                padding-bottom: 8px;
                                transition: all .2s ease-in-out;
                            }
                        }

                        &:hover, &:focus-visible {
                            .item {
                                box-shadow: 0 0 0 4px #F8F8FA;

                                &-name {
                                    color: $blueNewLight;
                                }
                            }
                        }
                    }

                    &.grid-item {
                        a {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;

                            .item {
                                &-name {
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    padding: 0;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        div#presentation {
            padding: 24px 0;
            background: $blueNew;

            @media screen and (max-width: 1023px) {
                padding: 0;
            }

            .grid-container {
                display: flex;
                justify-content: space-around;
                flex-wrap: nowrap;
                flex-direction: row;
                align-items: center;
                padding: 0;

                > div {
                    display: flex;
                    align-items: center;

                    img {
                        margin: auto;
                        padding-right: 8px;
                    }

                    .text {
                        color: #FFF;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 130%;
                        letter-spacing: 0.12px;
                    }
                }
            }
        }

        /************************
          CONTENT - DEFAULT PAGE
        ************************/

        &.default-page {
            #body-main {
                background-color: #fff;

                section {
                    &#content {
                        margin: 2rem 0;

                        h2 {
                            margin: 2rem 0 1rem 0;
                        }
                    }
                }
            }
        }

        &#error-404 {
            #body-main {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                a {
                    color: $blueNew;
                }
            }
        }

        /************************
            CONTENT - HOMEPAGE
        ************************/

        &#homepage {

            #body-main {
                background-color: $lightgrey;
            }

            section {
                &#banner {
                    min-height: 500px;
                    padding: 40px 0 80px;

                    .banner-container {
                        width: 100%;
                        position: relative;

                        #banner-content {

                            .cell {
                                position: relative;

                                &:first-child:before {
                                    display: block;
                                    content: '';
                                    position: relative;
                                    width: 312px;
                                    height: 92px;
                                    transform: translateX(-100px);
                                    margin-bottom: 32px;
                                    background: url("../images/trame.svg") repeat top right;

                                    @media screen and (max-width: 767px) {
                                        display: none;
                                    }
                                }

                                &:last-child:before {
                                    display: block;
                                    content: '';
                                    position: absolute;
                                    bottom: -50px;
                                    left: -200px;
                                    width: 200px;
                                    height: 250px;
                                    background: url("../images/trame.svg") repeat top left;

                                    @media screen and (max-width: 767px) {
                                        display: none;
                                    }
                                }
                            }

                            h1 {
                                color: $blueNew;

                                strong {
                                    color: $blueNewLight;
                                }
                            }

                            .img-cut-edges {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;

                                @media screen and (max-width: 767px) {
                                    height: auto;
                                    object-fit: none;
                                }
                            }
                        }
                    }
                }

                &#home-menu {
                    background: $blueNew;
                    margin: 0;
                    padding: 80px 0;

                    @media screen and (max-width: 1023px) {
                        padding: 80px 0 !important;
                    }

                    .grid-container {
                        .home-menu-title {
                            color: #fff;
                            font-size: 48px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 110%;
                            padding: 0 80px;

                            @media screen and (max-width: 1023px) {
                                padding: 0 24px;
                            }
                        }

                        .home-menu-container {
                            margin-top: 56px;
                            padding: 0 80px;
                            display: grid;
                            grid-template-columns: repeat(7, 1fr);
                            grid-auto-rows: 1fr;
                            grid-gap: 24px;

                            @media screen and (max-width: 1279px) {
                                grid-template-columns: repeat(4, 1fr);
                            }

                            @media screen and (max-width: 1023px) {
                                padding: 0 20px;
                            }

                            @media screen and (max-width: 640px) {
                                grid-template-columns: repeat(2, 1fr);
                            }

                            .cell {
                                padding: 0;
                                align-self: stretch;
                                border-radius: 8px;
                                background: #F8F8FA;
                                flex-grow: 1;
                                transition: all 0.2s ease-in-out;
                                box-shadow: 0 0 0 0 white;

                                &:hover, &:focus-visible {
                                    box-shadow: 0 0 0 4px white;

                                    .menu-item span {
                                        color: $blueNewLight;
                                    }
                                }

                                .menu-item {
                                    display: flex;
                                    padding: 8px;
                                    flex-direction: column;
                                    align-items: center;
                                    gap: 8px;
                                    height: 100%;

                                    img {
                                        max-width: 60px;
                                        margin: auto;
                                    }

                                    span {
                                        color: $blueNew;
                                        text-align: center;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 120%;
                                        margin: auto;
                                        transition: all 0.2s ease-in-out;
                                    }
                                }
                            }
                        }
                    }
                }

                &#flash {
                    max-width: 900px;
                    margin: 0 auto 3rem auto;
                    background-color: #D1D1D1;
                    padding: 1.5rem 1rem;

                    .grid-x {
                        .cell {

                            .flash-pic {
                                margin: 0 auto;
                                width: 80px;
                                height: 80px;
                                border-radius: 100%;
                                background-size: contain;
                            }

                            .flash-content {
                                p {
                                    margin: 0;
                                }

                                a {
                                    color: $blueNew;
                                }

                                .flash-title {
                                    font-weight: bold;
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }

                &#univers {
                    background: #fff;

                    .univers {
                        padding: 104px 0;
                        gap: 25px;
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);

                        @media screen and (max-width: 640px) {
                            padding: 80px 0;
                            grid-template-columns: auto;
                            row-gap: 24px;
                            column-gap: 15px;
                        }

                        div.cell:first-child {
                            grid-column: 1/4;
                            display: flex;
                            flex-direction: column;
                            margin: auto;
                            padding-left: 32px;
                            padding-right: 75px;

                            @media screen and (max-width: 640px) {
                                grid-column: 1/3;
                                padding: 0;
                                margin-bottom: 24px;
                            }
                        }

                        a.cell {
                            &:hover, &:focus-visible {
                                h3 {
                                    color: $blueNewLight
                                }
                            }
                        }

                        div.univers-menu-item-pic {
                            min-height: 300px;
                            width: 100%;
                            background-size: cover;
                            background-position: center center;
                            @extend .img-cut-edges;

                            @media screen and (max-width: 640px) {
                                min-height: 175px !important;
                            }
                        }

                        h2 {
                            font-size: 48px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 110%;
                            color: $blue;
                            text-transform: none;
                            margin: 0;
                        }

                        h3 {
                            padding-top: 15px;
                            font-size: 24px;
                            line-height: 125%;
                            transition: all 0.2s ease-in-out;

                            @media screen and (max-width: 640px) {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 120%;
                                margin: auto 0;
                            }
                        }

                        em {
                            color: $blueNewLight;
                            font-size: 32px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 110%;
                        }

                        .desc {
                            color: #323232;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 135%;
                            letter-spacing: 0.16px;
                            margin-top: 24px;
                        }

                        @media screen and (max-width: 640px) {
                            a.cell {
                                height: 100%;
                                display: inline-flex;
                                flex-direction: column;
                                width: 100%;
                            }
                        }
                    }
                }

                &#news {
                    background-color: #F8F8FA;
                    padding: 80px 0;

                    .title {
                        width: fit-content;
                        margin-bottom: 110px;

                        &.corner__top-left:before, &.corner__bottom-right:after {
                            height: 42px;
                        }

                        h2 {
                            font-size: 48px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 110%;
                            color: $blueNew;
                            text-transform: none;

                            width: fit-content;
                            margin: 0 24px;
                        }
                    }

                    .grid-x {
                        display: flex;
                        flex-direction: row;
                        gap: 24px;

                        .cell {
                            flex: 1 0 calc(33% - 24px * 2);
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            background: #FFF;
                            border-radius: 0 0 8px 8px;
                            transition: all 0.2s ease-in-out;
                            box-shadow: 0 4px 4px 0 rgba(0, 40, 100, 0.06);

                            &:hover, &:focus-visible {
                                box-shadow: 0 4px 4px 0 rgba(0, 40, 100, 0.2);

                                .product-desc h3 {
                                    color: $blueNewLight;
                                }
                            }

                            .product-pic {
                                img {
                                    object-fit: cover;
                                    width: 100%;
                                    height: 220px;
                                }
                            }

                            .product-desc {
                                display: flex;
                                height: 100%;
                                gap: 12px;
                                padding: 16px;
                                flex-direction: column;
                                align-items: flex-start;
                                border-radius: 0 0 8px 8px;

                                h3 {
                                    font-style: normal;
                                    font-size: 16px;
                                    font-weight: 600;
                                    line-height: 120%;
                                    color: $blueNew;
                                    margin-bottom: 0;
                                    text-transform: uppercase;
                                    transition: all 0.2s ease-in-out;
                                }

                                p {
                                    font-style: normal;
                                    font-size: 14px;
                                    font-weight: 300;
                                    line-height: 130%;
                                    letter-spacing: 0.14px;
                                    color: #323232;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: break-spaces;
                                    margin: 0;
                                }

                                a {
                                    &:before {
                                        display: block;
                                        content: '';
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }

                    .see-all {
                        @extend .grid-x;
                        margin-top: 40px;
                        justify-content: flex-end;
                        align-items: center;
                    }
                }
            }
        }

        /************************
            CONTENT - au-service-de-nos-clients
        ************************/

        &#au-service-de-nos-clients {
            #body-main {
                background-color: $lightgrey;
            }

            section {
                &#valeurs {
                    position: relative;

                    .grid-container {
                        box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.2);
                        background-color: #fff;
                        position: relative;

                        h3 {
                            margin: 4rem 0 2rem 0;
                        }

                        .valeur-item {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            text-align: center;

                            @media screen and (max-width: 767px) {
                                margin: 0 0 2rem;
                            }

                            h3 {
                                margin: .5rem 0;
                            }
                        }
                    }

                    &:after {
                        content: "";
                        display: block;
                        width: 100%;
                        height: 50vh;
                        background-color: #fff;
                        margin-top: -40vh;
                    }
                }

                &#histoire {
                    background-color: #fff;
                    padding-bottom: 5rem;

                    h3 {
                        margin: 1rem 0;
                    }

                    .swiper-container {
                        overflow: hidden;
                    }

                    .gallery-thumbs {

                        position: relative;

                        &:after {
                            position: absolute;
                            top: 12px;
                            content: "";
                            height: 2px;
                            left: 0;
                            right: 0;
                            background-color: #E5E5E5;
                        }

                        .swiper-wrapper {
                            .swiper-slide {
                                text-align: center;
                                position: relative;
                                display: block;

                                .year {
                                    position: relative;
                                    padding-top: 20px;
                                    font-size: .9rem;
                                    cursor: pointer;
                                    transition: all .4s;

                                    .line {
                                        margin: 0 auto;
                                        height: 20px;
                                        width: 2px;
                                        background-color: #E5E5E5;
                                        position: relative;
                                        padding-top: 15px;
                                        margin-bottom: 3px;

                                        &:after {
                                            position: absolute;
                                            top: -15px;
                                            right: calc(50% - 8px);
                                            content: "";
                                            height: 10px;
                                            width: 10px;
                                            border: 3px solid #E5E5E5;
                                            border-radius: 50%;
                                            display: block;
                                            background-color: #fff;
                                            z-index: 1;
                                            transition: all .4s;
                                        }
                                    }

                                    &:hover {
                                        .line {
                                            &:after {
                                                background-color: #E5E5E5;
                                            }
                                        }
                                    }
                                }

                                &.swiper-slide-thumb-active {
                                    .line {
                                        &:after {
                                            background-color: $blueNew;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .gallery-top {
                        margin-top: 2rem;

                        .swiper-wrapper {
                            max-width: 75%;

                            .swiper-slide {
                                background-color: #E5E5E5;

                                .grid-container {
                                    padding: .9375rem;

                                    .year-pic {
                                        min-height: 40vh;
                                        background-size: contain;
                                        background-position: center center;
                                        background-repeat: no-repeat;
                                    }

                                    .year-content {
                                        padding: 2rem;

                                        .year-title {
                                            color: $blueNew;
                                            font-size: 2rem;
                                            font-weight: bold;
                                            margin-bottom: 1rem;
                                        }
                                    }
                                }
                            }

                            .swiper-button-next {
                                right: -10px;

                            }

                            .swiper-button-prev {
                                left: -10px;
                            }
                        }

                    }
                }

                &#chiffres {
                    background-color: #fff;
                    color: #122257;
                    padding-bottom: 3rem;

                    h3 {
                        margin-bottom: 3rem;
                    }

                    .type-1 {
                        text-transform: uppercase;
                        font-size: 4rem;
                        line-height: 5rem;
                        color: #122257;
                        font-weight: bolder;
                        display: block;
                    }

                    .type-2 {
                        text-transform: uppercase;
                        font-size: 1.5rem;
                        line-height: 1.5rem;
                        color: $blueNew;
                        font-weight: bold;
                        display: block;
                        margin-bottom: .5rem;
                    }

                    .type-3 {
                        font-size: 4rem;
                        line-height: 4rem;
                        display: block;
                        color: #122257;
                    }

                    .type-5 {
                        font-size: 4rem;
                        line-height: 4rem;
                        color: $blueNew;
                        display: block;
                    }

                    .ca-1 {
                        margin-bottom: 1rem;
                    }

                    .ca-2 {
                        text-align: right;
                    }

                    .moteurs {

                        text-align: center;

                        img {
                            &:first-child {
                                margin-bottom: 1rem;
                            }

                            &:last-child {
                                margin-top: 1rem;
                            }

                        }

                        .type-6 {
                            display: block;
                            color: $blueNew;
                            font-size: 1.8rem;
                            line-height: 2rem;
                            font-weight: bolder;
                            text-transform: uppercase;
                        }

                        .type-7 {
                            display: block;
                            text-transform: uppercase;
                            font-weight: bold;
                        }

                        img.pic-bottom {
                            transform: rotate(0.5turn);
                        }
                    }

                    .pays {
                        max-width: 70%;
                    }

                    .clients {
                        text-align: right;

                        .type-5 {
                            color: #122257;
                        }
                    }
                }
            }
        }

        &#nos-services {
            .service {
                .pic {
                    position: relative;
                    border-radius: 100%;
                    overflow: hidden;
                    margin: 2rem auto;
                    width: 228px;
                    max-width: 100%;
                    background-position: center center;
                    background-repeat: no-repeat;

                    &:after {
                        display: block;
                        content: "";
                        padding-top: 100%;
                    }
                }
            }
        }

        &#nos-univers, &.template-univers\.php {

            #intro {
                em {
                    @extend h3;
                }
            }


            .univers {
                margin: 80px 0;

                @media screen and (max-width: 640px) {
                    gap: 24px;
                    margin: 40px 0;
                }

                .pic {
                    width: 100%;
                    min-height: 350px;
                    background-size: cover;
                    background-position: center center;
                }

                .button {
                    margin-bottom: 0;
                }
            }
        }

        /************************
          CONTENT - GOUVERNANCE
        ************************/

        &#gouvernance-team-simu {
            background-color: #fff;

            section {
                &#content {
                    margin: 2rem 0;

                    h2 {
                        margin: 2rem 0 1rem 0;
                    }
                }
            }
        }

        /************************
          CONTENT - POLITIQUE RSE
        ************************/

        &#developpement-durable {
            #body-main {
                section {
                    padding: 2rem 0;

                    &#intro {
                        background-color: #fff;
                    }

                    &#word {
                        background-color: $blueNew;
                        color: #fff;

                        h1, h2, h3 {
                            color: #ffffff;
                        }

                        img {
                            width: 100%;
                            max-width: 200px;
                            margin-bottom: .5rem;
                        }

                        .signature {
                            font-weight: bold;
                            font-size: 1.4rem;
                            margin-top: 1rem;
                        }

                        #grid-content {
                            margin: 2rem 0;
                        }
                    }

                    &#rse {
                        background-color: $blueNew;
                        color: #fff;

                        h1, h2, h3 {
                            color: #ffffff;
                        }

                        #simu-rse {
                            max-width: 250px;
                        }

                        .pilier-container {
                            .pilier {
                                position: relative;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                padding: 1rem;

                                span {
                                    display: block;
                                    text-transform: uppercase;
                                    margin-top: 1rem;
                                    font-weight: bold;
                                    font-size: 1.4rem;
                                    line-height: 1.6rem;
                                    max-width: 250px;
                                    text-align: center;
                                }
                            }

                            &-first {
                                .pilier:after {
                                    content: "";
                                    position: absolute;
                                    right: 0;
                                    top: 25%;
                                    height: 80px;
                                    width: 3px;
                                    background-color: #fff;
                                }
                            }
                        }

                        .h-line {
                            position: relative;

                            &:after {
                                content: "";
                                top: 50%;
                                position: absolute;
                                height: 3px;
                                width: 80px;
                                background-color: #fff;
                                left: 0;

                            }

                            &:nth-child(1):after {
                                right: 0;
                                left: auto;

                            }

                        }

                        .simu-rse-container {
                            background-image: url("../images/fleches.png");
                            background-size: contain;
                            background-position: center center;
                            background-repeat: no-repeat;
                            position: relative;
                            margin: 0 auto;

                            width: 250px;
                            height: 250px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &-text {
                                text-align: right;
                                text-transform: uppercase;
                                max-width: 75%;
                                font-size: 1.5rem;
                                line-height: 1.9rem;
                                transform: rotate(-10deg);


                            }
                        }
                    }

                    &#pride {
                        padding: 3rem 0 5rem 0;

                        h2 {
                            margin: 2rem 0;
                        }

                        img {
                            max-width: 150px;
                            margin-top: 1.5rem;
                        }

                        .desc {
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }

        /************************
        CONTENT - VISITE VIRTUELLE
        ************************/

        &#visite-virtuelle {
            #body-main {
                section {
                    &#content {
                        margin: 2rem 0;

                        h2 {
                            margin: 2rem 0 1rem 0;
                        }

                        iframe {
                            width: 100% !important;
                            min-height: 500px;
                        }
                    }

                    &#map {
                        .map-legend {
                            margin-top: 4rem;

                            .cell {
                                margin-bottom: 1rem;
                                display: flex;

                                .cell-content {
                                    width: 70%;

                                    .entete {
                                        display: flex;
                                        font-weight: bold;
                                        align-items: center;

                                        .number {
                                            min-width: 30px;
                                            height: 30px;
                                            background-color: $blueNew;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            color: #fff;
                                            font-weight: bold;
                                            border-radius: 100%;
                                            margin-right: 1rem;
                                        }

                                        .baseline {
                                            display: block;
                                            color: $blueNew;
                                        }
                                    }

                                    .desc {
                                        margin-top: .5rem;
                                        font-size: .9rem;
                                    }
                                }

                                .cell-pic {
                                    padding: 0 1.8rem 0 .4rem;

                                    img {
                                        max-width: 120px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        /************************
            CONTENT - WORLDWIDE
        ************************/

        &#simu-worldwide {
            section {
                &#content {
                    margin: 3rem 0;

                    .title-pink {
                        text-transform: none;
                    }

                    .adresse {
                        margin-bottom: 2rem;

                        strong {
                            text-transform: uppercase;

                        }
                    }
                }
            }
        }

        /************************
          CONTENT - PARTNERS
        ************************/

        &#simu-partner {
            section {
                &#quelques-chiffres {
                    margin: 4rem 0;

                    .number {
                        margin-top: 1.5rem;
                        color: $blue2;

                        strong {
                            font-size: 2rem;
                        }

                        em {
                            font-size: 1.2rem;
                            font-weight: bold;
                            text-transform: uppercase;
                            font-style: normal;
                        }

                        img {
                            margin-bottom: 1.5rem;
                        }
                    }
                }

                &#contact {
                    margin: 3rem 0;

                }
            }
        }

        /************************
          CONTENT - COMMANDER
        ************************/

        &#commander {
            section {
                &#content {
                    padding: 3rem 0;

                    h2 {
                        margin-top: 3rem;
                    }
                }
            }
        }


        /************************
          CONTENT - SAV GARANTIE
        ************************/

        &#sav-garantie {
            #body-main {
                background-color: $lightgrey;

                section {
                    &#intro {
                        .retour-qualite {
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .taux {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                position: absolute;
                                color: $blue2;
                                font-size: 2rem;

                                span {
                                    font-size: 4rem;
                                }

                                .legend {
                                    text-transform: uppercase;
                                    font-size: 1rem;
                                    margin-top: 1.5rem;

                                }
                            }
                        }
                    }

                    &#garanties {
                        position: relative;

                        .grid-container {
                            padding: 4rem 0;
                            box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.2);
                            background-color: #fff;
                            position: relative;

                            h2 {
                                margin: 4rem 0 2rem 0;
                            }

                            .garantie-item {
                                padding: 1rem;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                text-align: center;

                                h3 {
                                    margin: .5rem 0;
                                }
                            }
                        }

                        &:after {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 50vh;
                            background-color: #fff;
                            margin-top: -40vh;
                        }
                    }

                    &#sav {
                        background-color: #fff;

                        .title {
                            margin-left: 3rem;
                        }

                        .encart-container {
                            border: none;
                            padding: 3rem 1rem;

                            .encart {
                                background-color: $lightgrey;
                                padding: 3rem 2rem;
                                text-align: center;

                                .picto-container {
                                    margin-top: calc(-60px - 2rem);
                                    margin-bottom: 1rem;
                                }

                                strong, em, p {
                                    font-size: 1rem;
                                    line-height: 1rem;
                                    text-transform: none;
                                    margin-top: 1rem;
                                }

                                em {
                                    font-weight: normal;
                                }

                                .etiquette {

                                    font-size: 1rem;
                                    line-height: 1rem;
                                    margin-top: 1rem;
                                    text-transform: none;

                                    img {
                                        margin: 1rem 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        /*********************
         CONTENT - TUTORIELS
       **********************/

        &#tutoriels-dinstallation {
            section {
                &#intro {
                    .grid-container {
                        background-image: url(../dist/images/simu-fabricant-moteur-tutoriels-trait-rose.jpg);
                        background-repeat: no-repeat;
                        background-position: center right;
                    }
                }

                &#content {
                    margin: 5rem 0;

                    .tutorial-cat {
                        margin-top: 3rem;

                        a.button {
                            margin-bottom: 1.6rem;
                        }

                        h2 {
                            font-size: 1.5rem;

                            &:after {
                                width: 90%;
                                display: block;
                                content: "";
                                height: 2px;
                                background-color: $lightgrey;
                                margin-top: .5rem;
                            }
                        }
                    }

                    .tutorial {
                        .title {
                            color: $blueNew;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 120%;
                        }
                    }
                }
            }
        }

        /*********************
          FAQ
        **********************/

        &#faq, &#declarations-ce, &#telecharger-les-notices {
            section {
                &#content {
                    margin: 2rem 0;

                    .accordion {
                        a.accordion-title {
                            font-size: 1.1rem;
                            text-transform: uppercase;
                            color: #000;
                        }

                        .accordion {
                            a.accordion-title {
                                color: $blueNew;
                            }

                            .accordion {
                                li.accordion-item {
                                    a.accordion-title {
                                        color: #fff;
                                        background-color: $blue;

                                        &:hover {
                                            background-color: $blue2;
                                        }
                                    }

                                    .accordion {
                                        li.accordion-item {

                                            a.accordion-title {
                                                background-color: #fff;
                                                color: $blue;

                                                &:hover {
                                                    background-color: $lightgrey;

                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    table {
                        font-size: 1.2rem;

                        img {
                            max-width: 40px;
                        }
                    }
                }
            }
        }

        /*********************
          CONTENT - PRESSE
        **********************/


        &#presse {
            section {
                img {
                    max-width: 210px;
                }

                &#banner {
                    img {
                        max-width: none;
                    }
                }

                &#content {
                    h3 {
                        margin: 0 0 20px;
                    }

                    #communiques {
                        margin-bottom: 40px;

                        img {
                            margin: .5rem 0;
                        }
                    }

                    #revues {
                        margin-bottom: 40px;

                        td {
                            padding: 2rem;

                            p {
                                margin: 0;
                            }

                            a {
                                color: $blueNew;
                            }
                        }
                    }

                    #annonces {
                        margin-bottom: 40px;

                        td {
                            img {
                                margin: .5rem 0;
                            }

                            a {
                                color: $blueNew;
                            }
                        }
                    }
                }
            }
        }

        /******************************
          CONTENT - CHOISIR UN MOTEUR
        ******************************/

        &#choisir-un-moteur {
            section {

                &#calculateur {
                    padding: 56px 0;

                    form.konfigurator {

                        .lbl-result {
                            font-weight: bold;
                            display: none;

                        }

                        input#result {
                            display: none;
                            background-color: $lightgrey;
                            text-align: center;
                            border: none;
                            box-shadow: none;
                            pointer-events: none;
                            font-size: 2rem;
                            font-weight: bold;
                            color: $blueNew;
                            height: auto;
                        }
                    }
                }

                &#choisir {
                    padding: 2rem 0;

                    table {
                        margin-top: 1.5rem;
                    }
                }

                &#etiquettes {
                    .content {
                        img {
                            margin: 2rem 0;
                        }
                    }
                }
            }
        }

        /******************************
          CONTENT - CHOISIR UN ACCESSOIRE
        ******************************/

        &#les-accessoires-de-volets-roulants {
            section {
                &#intro {
                    position: relative;
                    background-image: url(../dist/images/simu-fabricant-moteur-choisir-un-accessoire-trait-rose.jpg);
                    background-size: contain;
                    background-position: left center;
                    background-repeat: no-repeat;

                    &:before {
                        content: "";
                        background-image: url(../dist/images/simu-fabricant-moteur-choisir-un-accessoire-trait-rose2.jpg);
                        background-size: contain;
                        background-position: right center;
                        background-repeat: no-repeat;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                    }

                    em {
                        @extend h3;
                    }

                    .grid-container {
                        position: relative;
                    }
                }

                &#accessoires-list {
                    margin: 3rem 0;

                    .accessoire-container {
                        margin: 1rem 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        text-align: center;

                        .title {
                            margin-top: 1rem;
                            font-size: 1.1rem;
                            font-weight: bold;
                            min-height: 42px;
                            display: flex;
                            align-items: center;
                        }

                        .pic {
                            width: 100%;
                            max-width: 200px;
                            height: 105px;
                        }

                        a.button {
                            padding: .85rem 1.5rem;
                        }
                    }
                }
            }
        }

        &#e-catalogue {
            section {
                &#content {
                    padding: 3rem 0;
                    position: relative;

                    .filter-container {
                        position: relative;
                        width: 345px;
                        padding: 24px 24px 72px;
                        background: #F8F8FA;
                        margin-right: auto;

                        @media screen and (max-width: 767px) {
                            width: 100%;
                            background: transparent;
                            padding: 0;
                        }

                        & > .grid-x {
                            gap: 32px;

                            @media screen and (max-width: 767px) {
                                display: none;
                                position: fixed;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                z-index: 1000;
                                padding: 24px;
                                background: #F8F8FA;
                                overflow: auto;
                            }
                        }

                        .btn-title {
                            display: flex;
                            color: $blueNew;
                            font-size: 32px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 110%;
                            gap: 8px;
                            align-items: center;
                            justify-content: flex-start;

                            svg:last-child:not(:first-child) {
                                margin-left: auto;
                            }

                            &.-close {
                                display: none;
                            }

                            @media screen and (max-width: 767px) {
                                margin-bottom: 0;
                                width: 100%;

                                &.-open {
                                    background: #F8F8FA;
                                    padding: 24px 16px;
                                    margin: 0 -16px 32px;
                                    width: calc(100% + 32px);
                                }

                                &.-close {
                                    display: flex;
                                }
                            }
                        }

                        #loading {
                            top: 0;
                            bottom: 0;
                            min-height: 30vh;
                            width: 100%;
                            position: absolute;
                            display: flex;
                            z-index: 999;
                            background-color: rgba(255, 255, 255, .9);
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            div.status {
                                z-index: 100;
                                margin-bottom: -40px;
                                font-size: .9rem;
                                color: $blueNew;
                            }
                        }

                        .accordion {
                            margin-top: 2rem;

                            .accordion-title {
                                background-color: $blueNew;
                                font-size: 1rem;
                                color: #fff;
                            }

                            .accordion-item:first-child {
                                .accordion-content {
                                    padding-bottom: 1rem;
                                }
                            }
                        }

                        label {
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 125%;
                            color: $blueNew;
                        }

                        select {
                            margin-bottom: 0;
                        }
                    }
                }

                #product-list {
                    width: calc(100% - 345px - 24px);
                    display: block;
                    min-height: 280px;

                    @media screen and (max-width: 767px) {
                        width: calc(100% + 20px);
                        margin: 0px -10px;
                    }

                    #product-grid {
                        width: 100%;
                        position: relative;

                        &:after {
                            content: '';
                            display: block;
                            clear: both;
                        }

                        .grid-sizer {
                            width: 25%;

                            @media screen and (max-width: 1023px) {
                                width: 50%;
                            }
                        }

                        .grid-item {
                            float: left;
                            position: relative;
                            width: 25%;
                            height: 0;
                            padding-top: 31%;
                            display: flex;
                            transition: all .4s;

                            @media screen and (max-width: 1279px) {
                                width: 33%;
                                padding-top: 43%;
                            }

                            @media screen and (max-width: 1023px) {
                                width: 50%;
                                padding-top: 62%;
                            }
                        }
                    }
                }
            }
        }

        /***************************
          OUTIL D'AIDE À LA VENTE
        ****************************/


        &#outils-daide-a-la-vente {

            section {
                &#content {
                    padding: 0;

                    .title {
                        background-color: $lightgrey;
                        padding: 2rem 0;
                    }

                    .tool-content {
                        .tool {
                            margin: 1.5rem 0;
                            font-size: .9rem;
                            position: relative;

                            .free {
                                position: absolute;
                                top: 1rem;
                                right: 1rem;
                                font-size: .8rem;
                                text-align: center;
                                border-radius: 100%;
                                font-weight: bold;
                                color: #fff;
                                background-color: $blueNew;
                                width: 60px;
                                height: 60px;
                                transform: rotate(-15deg);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            p {
                                font-size: .9rem;
                            }

                            strong {
                                font-weight: strong;
                            }

                            .pic {
                                padding: 0rem 1rem;
                                border: 1px solid $lightgrey;

                            }

                            h3 {
                                margin: 1rem 0 .5rem 0;
                            }

                            ul {
                                margin: 0;

                                li {

                                }
                            }

                            .details {
                                display: flex;

                                .price {
                                    text-transform: uppercase;
                                    color: $blueNew;
                                    font-weight: bold;
                                    margin-right: .5rem;
                                }

                                .ref {

                                }
                            }
                        }
                    }
                }
            }

        }

        &#telechargez-nos-catalogues {
            #catalogues {
                .catalogue {
                    color: #000;
                    text-align: center;

                    img {
                        max-height: 220px
                    }

                }
            }
        }

        /***********
          CONTACT
        **********/

        &#contact {
            overflow-x: hidden;

            section {
                &#intro {
                    a.button {
                        margin: .5rem;
                    }

                }

                &#content {
                    margin-bottom: 3rem;

                    .content-container {
                        padding: 2rem 0;
                    }
                }
            }
        }

        &.single-page {
            section {
                &#banner {
                    .title {
                        @extend h1;
                    }
                }

                &#content {
                    margin: 2rem 0;

                    li {
                        list-style: disc;
                    }
                }
            }
        }

        /***************************
          CONTENT :  BLOG SIMULIVE
        ***************************/

        &#blog-simulive {
            section {
                &#content {
                    margin-top: 3rem;

                    .actualite {
                        min-width: 100%;
                        border-bottom: 1px solid $lightgrey;
                        padding-bottom: 1.5rem;
                        margin-bottom: 1.5rem;

                        .details {
                            display: flex;
                            margin-bottom: .8rem;

                            .date {
                                text-transform: uppercase;
                                color: $blue;
                                font-size: .9rem;
                                margin-right: 1rem;
                            }

                            .cat {
                                background-color: $blue2;
                                color: #fff;
                                text-transform: uppercase;
                                font-size: .8rem;
                                padding: 0 .5rem;
                                margin: 0 .2rem;
                            }
                        }

                        .read-more {
                            margin-top: .8rem;

                            a {
                                @extend .link;
                            }
                        }
                    }

                    .themes {
                        margin-bottom: 2rem;

                        .title {
                            display: block;
                            text-transform: uppercase;
                            font-size: 1.3rem;
                            border-bottom: 2px solid #CCCCCC;
                            padding-bottom: .5rem;
                            margin-bottom: .5rem;


                        }

                        button.button {
                            border-radius: 0;
                            background-color: #fff;
                            color: #8B8B8B;
                            border-color: #CCCCCC;
                            font-size: .8rem;
                            margin: 3px 2px;

                            &:hover {
                                background-color: $lightgrey;
                            }
                        }
                    }
                }
            }
        }

        &#technologies {
            section {
                &#content {
                    h2 {
                        margin: 2rem 0;
                        color: $blueNew;
                    }


                }
            }
        }

        /***************************
          CONTENT :  ACTUALITES
        ***************************/

        &#actualites {
            .product {
                margin: 2rem 0;

                .product-pic {
                    min-height: 200px;
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;

                    @media screen and (max-width: 1023px) {
                        margin-bottom: 16px;
                    }
                }

                .desc {
                    .read-more {
                        margin-top: .8rem;

                        a {
                            @extend .link;
                        }
                    }
                }
            }
        }

        &#conditions-generales-des-garanties {
            .button.button-blue {
                margin: .5rem 0;
            }
        }

        &#telecharger-les-notices {
            #assistance {
                ul {
                    li {
                        list-style: disc;
                    }
                }

                .sav-pro {
                    margin-top: 1rem;
                }


            }
        }

        /***************************
         SINGLE :  PRODUCT & GAMME
        ***************************/

        &#fiche-gamme,
        &#fiche-produit {
            #body-main {
                section.pdp {
                    padding-top: 120px;

                    @media screen and (max-width: 767px) {
                        padding-top: 80px !important;
                    }

                    .pdp {
                        &-visual {
                            margin-bottom: 80px;

                            @media screen and (max-width: 767px) {
                                margin-bottom: 40px;
                            }

                            &:before, &:after {
                                height: 42px;
                            }

                            img {
                                margin: 0 54px;
                                width: calc(100% - 108px);

                                @media screen and (max-width: 1279px) {
                                    margin: 0 32px;
                                    width: calc(100% - 64px);
                                }
                            }


                            @media screen and (max-width: 767px) {
                                margin-bottom: 40px;
                            }
                        }

                        &-badge {
                            display: inline-block;
                            padding: 8px 16px;
                            color: white;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 130%;
                            letter-spacing: 0.12px;
                            margin-bottom: 24px;
                            border-radius: 40px;

                            @media screen and (max-width: 767px) {
                                margin-bottom: 16px;
                            }

                            &.--gamme {
                                background: #1EBACD;
                            }

                            &.--product {
                                background: #EF5958;
                            }
                        }


                        &-wysiwyg {
                            margin-bottom: 80px;

                            @media screen and (max-width: 767px) {
                                margin-bottom: 40px;
                            }

                            & + {
                                .button.button-blue-2 {
                                    margin: -56px 0 80px;

                                    @media screen and (max-width: 767px) {
                                        margin: -20px 0 60px;
                                    }
                                }
                            }
                        }

                        &-docs {
                            margin: 0 0 80px;
                            padding: 0;
                            list-style: none;

                            li {
                                border-bottom: 1px solid #D9D9D9;

                                a {
                                    color: $blueNew;
                                    padding: 16px 0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 130%;
                                    letter-spacing: 0.16px;
                                    transition: all 0.2s ease-in-out;

                                    svg {
                                        min-width: 24px;
                                        margin-left: 16px;
                                        margin-right: 16px;
                                        fill: none;
                                        stroke: #131A29;
                                        transition: all 0.2s ease-in-out;
                                    }

                                    &:hover, &:focus-visible {
                                        color: $blueNewLight;

                                        svg {
                                            stroke: $blueNewLight;
                                        }
                                    }
                                }
                            }
                        }

                        &-subtitle {
                            color: $blueNew;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 125%;
                            margin-bottom: 16px;
                        }

                        &-reference {
                            color: $blueNew;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 110%;
                            display: block;
                            margin-bottom: 12px;
                        }

                        &-table {
                            margin-bottom: 80px;
                            border-top: 2px solid #D9D9D9;

                            thead {
                                position: absolute;
                                width: 1px;
                                height: 1px;
                                padding: 0;
                                margin: -1px;
                                overflow: hidden;
                                clip: rect(0, 0, 0, 0);
                                border: 0;
                            }

                            tbody, tfoot, thead {
                                border: none;
                                background: none;

                                tr {
                                    background: none;
                                }

                                td {
                                    padding: 16px 0;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 300;
                                    line-height: 135%;
                                    letter-spacing: 0.16px;
                                    border-bottom: 1px solid #D9D9D9;

                                    &:not(:first-child) {
                                        padding-left: 16px;
                                    }
                                }
                            }
                        }

                        &-products {
                            margin-top: 80px;
                            padding: 56px 40px;
                            background: #F8F8FA;
                            border-top: 2px solid $blueNewLight;

                            @media screen and (max-width: 767px) {
                                padding: 40px 0;
                                margin: 40px -15px 0;
                            }

                            &-title {
                                text-align: center;
                                margin-bottom: 48px;

                                @media screen and (max-width: 767px) {
                                    text-align: left;
                                    padding: 0 15px;
                                    margin-bottom: 40px;
                                }
                            }

                            ul {
                                margin: 0;
                            }

                            a {
                                .item {
                                    background: white;
                                }

                                &:hover, &:focus-visible {
                                    .item {
                                        box-shadow: 0 0 0 4px white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    footer {
        background-color: $lightgrey;
        color: $blueNew;

        p {
            font-style: normal;
            font-weight: 300;
            line-height: 130%;
            color: $blueNew;

            font-size: 16px;
            letter-spacing: 0.16px;

            &.footer-heading {
                text-transform: uppercase;
                font-weight: 700;
            }
        }

        a {
            font-style: normal;
            font-weight: 300;
            line-height: 130%;
            color: $blueNew;

            font-size: 14px;
            letter-spacing: 0.14px;

            strong {
                font-weight: 600;
            }
        }

        .main-footer {
            padding: 40px 0;
        }

        #menu-footer-menu {
            display: inline-grid;
            margin: 0;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 10px 24px;
            width: 100%;
        }

        #footer-logo {
            margin-bottom: 20px;
        }

        .social-network {
            display: flex;
            justify-content: flex-start;
            gap: 20px;
        }

        .credits {
            padding: 16px 0;
            border-top: 1px solid #D9D9D9;
            background: #fff;

            a {
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 130%;
                letter-spacing: 0.12px;
                color: $blueNew;

                &:hover {
                    color: $blueNewLight;
                }
            }

            .text-center {
                display: inline-flex;
                margin: auto;
                justify-content: center;
                gap: 24px;
            }

            img.simu-brand {
                max-width: 300px;
                margin: auto;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    body {
        .wrapper {
            &#homepage section#banner .banner-container #banner-content {

                a {
                    margin: 0;
                }

                img {
                    margin-top: 40px;
                }
            }

            &#tutoriels-dinstallation {
                section {
                    &#intro {
                        .grid-container {
                            background-image: none;
                        }
                    }
                }
            }
        }

        div#presentation {
            .grid-container {
                padding: 40px 0 !important;
                flex-direction: column !important;
                gap: 16px;

                > div {
                    flex-direction: column !important;
                    gap: 8px;
                }
            }
        }

        footer {
            text-align: center;

            .cell:nth-child(2) {
                padding-top: 40px;
            }

            .footer-heading {
                font-size: 20px;
                font-weight: 600;
            }

            #menu-footer-menu {
                gap: 16px;
                grid-template-columns: auto;

                @media screen and (max-width: 1023px) {
                    margin-bottom: 16px;
                }

                a {
                    font-size: 16px;
                }
            }

            .social-network {
                gap: 24px;
                justify-content: center;

                img {
                    width: 32px;
                    height: 32px;
                }
            }

            .credits {
                padding: 40px 0;

                .text-center {
                    flex-direction: column;

                    a {
                        font-size: 16px;
                        letter-spacing: 0.16px;
                    }
                }

                .cell:nth-child(2) {
                    padding-top: 32px;

                    @media screen and (max-width: 767px) {
                        margin-top: 1rem;
                    }

                    img {
                        width: 250px;
                        margin: auto;
                    }
                }
            }
        }
    }
}

//foundation small
@media screen and (max-width: 767px) {
    body {
        .wrapper {
            &#e-catalogue {
                section {
                    &#product-list {
                        #product-grid {
                            .grid-item, .grid-sizer {
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }
    }
}

//foundation small
@media screen and (max-width: 640px) {
    body {

        .wrapper {
            #body-main {
                section {

                    &#home-menu {

                        .grid-container {

                            .home-menu-container > div {
                                justify-content: flex-start;

                                .cell {
                                    flex: 0 0 calc((100% - 24px) / 2) !important;
                                }
                            }
                        }
                    }

                    &#news {
                        padding: 80px 0 !important;

                        .title {
                            margin-bottom: 72px;
                        }

                        .grid-x {
                            flex-direction: column;
                        }
                    }
                }
            }

            &#homepage {
                section {
                    &#flash {
                        text-align: center;

                        .flash-title {
                            margin-top: .6em;
                        }
                    }
                }
            }

            &#e-catalogue {
                section {
                    &#product-list {
                        #product-grid {
                            .grid-item, .grid-sizer {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

/** Contact validation */
.swal2-icon-show {
    display: flex;
    margin: 30px auto 0 auto;
}

.swal2-success-circular-line-left, .swal2-success-circular-line-right, .swal2-success-fix {
    background-color: unset;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color: #002864;
}
