.landing-hero {
  position: relative;
  padding: 8rem 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 36vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.25rem;

  @media screen and (max-width: 1023px) {
    min-height: 0;
    padding: 4rem 0 1.5rem;
  }

  &:before {
    z-index: 20;
    content: "";
    display: block;
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 92px;
    height: 97px;
    background: url("../images/icons/pattern-white.svg") no-repeat center;

    @media screen and (max-width: 1023px) {
      top: .5rem;
      left: .5rem;
      width: 2.875rem;
      height: 3rem;
      background-size: cover;
    }
  }

  &:after {
    z-index: 20;
    content: "";
    display: block;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    width: 92px;
    height: 97px;
    background: url("../images/icons/pattern-white.svg") no-repeat center;

    @media screen and (max-width: 1023px) {
      bottom: .5rem;
      right: .5rem;
      width: 2.875rem;
      height: 3rem;
      background-size: cover;
    }
  }

  &-title {
    width: 50%;
    color: #FFF;
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;

    @media screen and (max-width: 1023px) {
      font-size: 2rem;
      width: 100%;
    }
  }

  &-content {
    position: relative;
    z-index: 20;
  }

  &-cta {

  }

  .grid-container {
    &:before {
      z-index: 10;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(270deg, rgba(0, 40, 100, 0.00) 0.03%, #002864 100%);
    }
  }
}

.landing-expertise {
  padding: 3.5rem 0;

  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
  }

  .items {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
    margin: 2.5rem 0 0;
    padding: 0;
    list-style: none;

    @media screen and (max-width: 1023px) {
      grid-template-columns: 1fr;
    }
  }

  .item {
    height: 100%;
    overflow: hidden;
    background: #F8F8FA;
    border-radius: 0.5rem;
    box-shadow: 0 4px 4px 0 rgba(0, 40, 100, 0.06);

    &-content {
      padding: var(--Spacing-4, 1.5rem);
    }

    &-visual {
      object-fit: cover;
      height: 15.5625rem;
      width: 100%;

      &.-sm {
        display: none;

        @media screen and (max-width: 1023px) {
          display: block;
        }
      }

      &.-lg {
        display: block;

        @media screen and (max-width: 1023px) {
          display: none;
        }
      }
    }

    &-title {
      color: $blueNew;
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 110%;
      margin-bottom: 0.5rem;
    }

    &-description {
      color: #323232;
      font-size: 1rem;
      font-weight: 300;
      line-height: 135%;
      letter-spacing: 0.01rem;

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.landing-video {
  position: relative;
  padding: 4.5rem 0;

  &:before, &:after {
    z-index: -1;
    content: "";
    display: block;
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 133px;
    height: 130px;
    background: url("../images/icons/pattern.svg") no-repeat center;

    @media screen and (max-width: 1023px) {
      left: 0;
    }
  }

  &:after {
    top: auto;
    left: auto;
    bottom: 1rem;
    right: 1rem;

    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  .video-wrapper {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    border-radius: 1rem;

    @media screen and (max-width: 1023px) {
      margin-bottom: 2rem;
    }

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
}

.landing-corners {
  position: relative;
  margin: 0;
  padding: 3rem 0;
  background: $blueNew;
  overflow: hidden;

  @media screen and (max-width: 1023px) {
    padding: 2.5rem 0 !important;

  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    width: 92px;
    height: 97px;
    background: url("../images/icons/pattern-red.svg") no-repeat center;

    @media screen and (max-width: 1023px) {
      bottom: -1.5rem;
      left: -1.5rem;
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 92px;
    height: 97px;
    background: url("../images/icons/pattern-red.svg") no-repeat center;

    @media screen and (max-width: 1023px) {
      top: -1.5rem;
      right: -1.5rem;
    }
  }

  &-content {
    padding: 0 5rem;

    @media screen and (max-width: 1023px) {
      padding: 0 1.5rem;
    }
  }

  &-title {
    color: #fff;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 1023px) {
      text-align: center;
    }
  }

  &-text {
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 135%; /* 1.35rem */
    letter-spacing: 0.01rem;

    p {
      margin-bottom: 1.5rem;
    }
  }
}

.list-product-card {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr;
  margin: 0;
  padding: 0;
  list-style: none;

  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
}

.product-card {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  background: white;
  border-radius: 0.5rem;

  &-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    span {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 110%;
    }
  }

  ul {
    margin: 1rem 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: .5rem;

    .button {
      margin: 0;
    }
  }
}

.landing-contact {
  padding: 4.5rem 0;
  overflow: hidden;
}
