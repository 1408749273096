@import 'settings';

.corner {
    &_ {
        content: "";
        display: block;
        height: 80px;
        background-color: $blueNew;
        -webkit-mask-size: contain !important;
    }

    &__top-left:before {
        @extend .corner_;
        -webkit-mask: url("../images/icons/corner-top-left.svg") no-repeat 0 50%;
    }

    &__bottom-right:after {
        @extend .corner_;
        -webkit-mask: url("../images/icons/corner-bottom-right.svg") no-repeat 100% 100%;
    }

    &__blue {
        &:before, &:after {
            background-color: $blueNew;
        }

        &-light {
            &:before, &:after {
                background-color: $blueNewLight;
            }
        }
    }

    &__white {
        &:before, &:after {
            background-color: white;
        }
    }
}

.img-cut-edges {
    -webkit-clip-path: polygon(5% 0%, 0% 0%, 100% 0%, 100% 95%, 95% 100%, 0% 100%, 0% 100%, 0% 5%);
    clip-path: polygon(5% 0%, 0% 0%, 100% 0%, 100% 95%, 95% 100%, 0% 100%, 0% 100%, 0% 5%);
}

#breadcrumbs-container {
    margin-bottom: 32px;

    #body-main > & {
        left: 0;
        z-index: 20;
        position: absolute;
        padding: 16px 24px 16px max(calc((100vw - 1512px) / 2 + 80px), 80px);
        border-radius: 0 0 24px 0;
        background: rgba(255, 255, 255, 0.56);

        @media screen and (max-width: 1023px) {
            padding-left: 15px;
            margin-right: 15px;
        }

    }

    p {
        margin: 0;
    }

    #breadcrumbs {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;

        @media screen and (max-width: 1023px) {
            font-size: 14px;
        }

        a {
            color: $blueNew;

            &:hover, &:focus {
                color: $blueNewLight;
            }
        }

        span {
            color: #323232;
        }
    }
}

.form {
    margin-left: -15px;
    margin-right: -15px;

    @media screen and (max-width: 1023px) {
        margin-left: -10px;
        margin-right: -10px;
        overflow: hidden;
    }

    .required {
        color: $blueNew;
        padding-left: .5rem;
        font-size: 1.5rem;
        position: absolute;
        top: 0;
    }

    i {
        display: block;
        font-size: 12px;
        margin-top: -24px;
        margin-bottom: 24px;
    }

    label {
        position: relative;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 135%;
        letter-spacing: 0.16px;
    }

    [type=color], [type=date], [type=datetime-local], [type=datetime], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=time], [type=url], [type=week], textarea, select {
        color: #5B5B5B;
        background: #FFF;
        padding: 6px 10px;
        border-radius: 4px;
        border: 1px solid #848484;
        box-shadow: none;
        min-height: 52px;
        height: auto;
        margin-bottom: 32px;
        margin-top: 6px;
    }

    select {
        background: #ffffff url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 16C12.2652 16 12.5196 15.8946 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12 13.5858L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.4804 15.8946 11.7348 16 12 16Z" fill="black"/> </svg>') no-repeat 98% center;
    }
}


.slider-container {
    margin-top: 24px;
    border-radius: 8px;
    padding: 32px 24px 8px;
    border: none;
    background: rgba(217, 217, 217, 0.25);

    .noUi-horizontal {

        background: #D9D9D9;
        border-radius: 20px;
        border: none;

        .noUi-connect {
            background: $blueNewLight;
        }

        &[disabled] .noUi-connect {
            background: #b8b8b8;
        }

        .noUi-tooltip {
            background: transparent;
            border: none;
            padding: 0;
        }

        .noUi-handle {
            width: 18px;
            height: 18px;
            top: 0px;
            right: -9px;
            border: none;
            border-radius: 100%;
            box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.15);

            &:after, &:before {
                display: none;
            }
        }
    }
}
